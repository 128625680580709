

























































import { Component, Watch } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import {
  BrandTeaserModule,
  BrandTeaserModuleContentInput,
  UnresolvedBrandData
} from '../BrandTeaser.contracts'
import { DashmixBoxTabItem, DashmixIconName } from '@movecloser/ui-core'

import { Inject, REFRESH_MDE_KEY } from '../../../support'

import { AbstractModuleForm } from '../../abstract'
import { FormFieldset } from '../../partials/FormFieldset'

import { brandTeaserContentFactory } from '../BrandTeaser.factory'

import { BrandTab, BrandTabLabel } from './partials'

/**
 * Form component for the `BrandTeaser` module.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<BrandTeaserModuleForm>({
  name: 'BrandTeaserModuleForm',
  components: {
    FormFieldset,
    BrandTab
  }
})
export class BrandTeaserModuleForm extends AbstractModuleForm<BrandTeaserModuleContentInput, BrandTeaserModule> {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  protected initialContent = brandTeaserContentFactory()

  public icons = DashmixIconName

  public activeTab: string | null = '0'

  public get hasBrands (): boolean {
    return Array.isArray(this.brands) && this.brands.length > 0
  }

  public get brands (): DashmixBoxTabItem[] {
    return [
      ...this._content.brands.map((brand, index) => {
        return {
          props: {
            brand,
            index
          },
          tab: {
            id: `brand-${index + 1}`,
            disabled: false,
            label: BrandTabLabel,
            props: {
              tabIndex: index,
              label: `brand-${index + 1}`,
              onSlideRemove: this.removeBrand
            }
          }
        }
      })
    ]
  }

  public set brands (brands: DashmixBoxTabItem[]) {
    this._content.brands = brands.map((brand: DashmixBoxTabItem) => {
      if (!brand.props) {
        return {} as UnresolvedBrandData
      }

      return brand.props.brand as UnresolvedBrandData
    })
  }

  /**
   * Creates new empty slide
   */
  public createBrand (): void {
    this._content.brands.push(this.addNewBrand())

    this.activeTab = `brand-${this._content.brands.length}`
  }

  public updateBrand (brand: UnresolvedBrandData, index: number): void {
    if (typeof this._content.brands === 'undefined' || !Array.isArray(this._content.brands)) {
      throw new Error(
        'BreadTeaserModuleForm.updateBrand(): Try to update brand in not existing brands list!')
    }

    const contentBrandCopy: UnresolvedBrandData[] = [...this._content.brands]

    contentBrandCopy[index] = brand
    this._content.brands = contentBrandCopy
  }

  protected addNewBrand (): UnresolvedBrandData {
    return { category: null }
  }

  private removeBrand (index: number): void {
    if (typeof this._content.brands === 'undefined' || !Array.isArray(this._content.brands)) {
      throw new Error(
        'BrandTeaserModuleForm.removeBrand(): Try to remove brand from not existing brands list!')
    }

    this._content.brands.splice(index, 1)

    if (this._content.brands.length > 0) {
      if (index !== 0) {
        this.activeTab = this.brands[index - 1].tab.id.toString()
      } else {
        this.activeTab = this.brands[index].tab.id.toString()
      }
    } else {
      this.activeTab = null
    }
  }

  @Watch('activeTab')
  protected onActiveTabChange (): void {
    this.eventBus.emit(REFRESH_MDE_KEY)
  }
}

export default BrandTeaserModuleForm
