












import { Component, Prop } from 'vue-property-decorator'
import { AbstractModuleForm } from '../../abstract'
import {
  NavigationCTABoxModule,
  NavigationCTABoxModuleContentInput
} from '../NavigationCTABox.contracts'
import { navbarContentFactory } from '../../Navbar/Navbar.factory'
import { ProductForm } from '../../partials/ProductForm'
import { PickerCallback } from '../../../contexts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<NavigationCTABoxModuleForm>({
  name: 'TextModuleForm',
  components: {
    ProductForm
  }
})
export class NavigationCTABoxModuleForm extends AbstractModuleForm<NavigationCTABoxModuleContentInput, NavigationCTABoxModule> {
  /**
   * Determines navigation cta box initial content.
   */
  protected initialContent = navbarContentFactory()

  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback
}

export default NavigationCTABoxModuleForm
