

























import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract'
import { articleTeaserBoxContentFactory } from '../ArticlesTeaserBox.factory'
import {
  ArticlesTeaserBoxModule,
  ArticlesTeaserBoxModuleContent,
  ArticlesTeaserBoxModuleContentInput, UnresolvedArticlesTeaserBoxData
} from '../ArticlesTeaserBox.contracts'
import { FormAddBtn } from '../../partials/FormAddBtn'
import { FormFieldset } from '../../partials/FormFieldset'
import { Iterator } from '../../partials/Iterator'
import { LinkForm } from '../../partials/LinkForm'
import { PickerCallback, PossibleRelatedPicker } from '../../../contexts'
import { SetForm } from '../../partials/SetForm'

import ArticlesTeaserFormItem from './partials/ArticlesTeaserFormItem.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ArticlesTeaserBoxModuleForm>({
  name: 'ArticlesTeaserBoxModuleForm',
  components: { ArticlesTeaserFormItem, LinkForm, FormAddBtn, FormFieldset, Iterator, SetForm }
})
export class ArticlesTeaserBoxModuleForm extends AbstractModuleForm<ArticlesTeaserBoxModuleContentInput, ArticlesTeaserBoxModule> {
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  public icons = DashmixIconName
  public initialContent: ArticlesTeaserBoxModuleContent = articleTeaserBoxContentFactory()

  public picker = PossibleRelatedPicker

  public addItem (): UnresolvedArticlesTeaserBoxData {
    return { article: null, articlesSet: null, name: '' }
  }
}

export default ArticlesTeaserBoxModuleForm
