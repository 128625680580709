














































import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract'
import { NavigationForm } from '../../partials/NavigationForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { LinkForm } from '../../partials/LinkForm/LinkForm.vue'
import { MarkdownEditor } from '../../partials/MarkdownEditor'

import { NavbarModule, NavbarModuleContentInput } from '../Navbar.contracts'
import { navbarContentFactory } from '../Navbar.factory'
import { NavbarModuleVersion } from '../Navbar.config'

@Component<NavbarModuleForm>({
  name: 'NavbarModuleForm',
  components: { NavigationForm, FormFieldset, ImageForm, LinkForm, MarkdownEditor }
})
export class NavbarModuleForm extends AbstractModuleForm<NavbarModuleContentInput, NavbarModule> {
  protected initialContent = navbarContentFactory()

  public get defaultVersion (): boolean {
    return this._version === NavbarModuleVersion.Default
  }

  public get simpleVersion (): boolean {
    return this._version === NavbarModuleVersion.Simple
  }

  public get image (): UnresolvedImage | null {
    if (!this._content.brandImage) {
      return null
    }
    return {
      image: this._content.brandImage
    }
  }

  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const contentCopy = { ...this._content }
      delete contentCopy.brandImage
      this._content = contentCopy
      return
    }
    this._content = {
      ...this._content,
      brandImage: image.image
    }
  }
}

export default NavbarModuleForm
