




































import { Component, Inject } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract'
import { defaultProvider, IS_SMART_MODULES_MODE_KEY } from '../../../support'

import {
  ProductSpecificationColors
} from '../../../front/products/organisms/ProductSpecification/ProductSpecification.contracts'

import { FormFieldset } from '../../partials/FormFieldset'
import { LinkForm } from '../../partials/LinkForm'
import { ProductForm } from '../../partials/ProductForm'
import { HASH_PRODUCT } from '../../partials/ProductForm/ProductForm.config'

import { productSpecificationContentFactory } from '../ProductSpecification.factory'
import {
  ProductSpecificationModule,
  ProductSpecificationModuleContent,
  ProductSpecificationModuleContentInput
} from '../ProductSpecification.contracts'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<ProductSpecificationModuleForm>({
  name: 'ProductSpecificationModuleForm',
  components: { FormFieldset, LinkForm, ProductForm },
  created () {
    this.$nextTick(() => {
      this.setColors()
    })
  }
})
export class ProductSpecificationModuleForm extends AbstractModuleForm<ProductSpecificationModuleContentInput,
  ProductSpecificationModule> {
  @Inject({
    from: IS_SMART_MODULES_MODE_KEY,
    default: () => defaultProvider<boolean>(false)
  })
  public readonly isSmart!: () => boolean

  public initialContent: ProductSpecificationModuleContent = productSpecificationContentFactory()
  public isSelectedCustomColor: boolean = true
  public productSpecificationColor: ProductSpecificationColors = {
    content: '',
    heading: '',
    navTabs: '',
    subHeading: '',
    background: '',
    backgroundBox: '',
    boxSectionContentTitle: '',
    stepSectionBoxShadow: '',
    stepSectionStepBackground: '',
    stepSectionStepColor: ''
  }

  public get isSelectedDefaultProduct (): boolean {
    if (!this._content.product || !!this._content.product.value) {
      return false
    }

    return !(this._content.product.value === HASH_PRODUCT)
  }

  public changeProduct () {
    this.setColors()
  }

  public clearColors () {
    this._content.color = {}
    delete this._content.color
  }

  public onColorUpdate (key: string, value: string): void {
    this._content = {
      ...this._content,
      color: {
        ...this._content.color,
        [key]: value
      }
    }
    this.$forceUpdate()
  }

  public onClear () {
    this.clearColors()
  }

  public async onSelectCustomColor (value: boolean) {
    this.isSelectedCustomColor = value
    if (value) {
      // I don't understand why I can't remove not empty key
      this._content.color = null
      this.clearColors()
      return
    }
    this._content.color = { ...this.productSpecificationColor }
    this._content = {
      ...this._content,
      color: {
        ...this.productSpecificationColor
      }
    }
    this.$forceUpdate()
  }

  public setColors () {
    if (this._content.color) {
      this.isSelectedCustomColor = false
      this.productSpecificationColor = { ...this._content.color }
    } else {
      this.clearColors()
    }
  }
}

export default ProductSpecificationModuleForm
