










import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract'

import { contactFormContentFactory } from '../ContactForm.factory'
import {
  ContactFormModule,
  ContactFormModuleContent,
  ContactFormModuleContentInput
} from '../ContactForm.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ContactFormModuleForm>({
  name: 'ContactFormModuleForm',
  components: {}
})
export class ContactFormModuleForm extends AbstractModuleForm<ContactFormModuleContentInput, ContactFormModule> {
  public icons = DashmixIconName
  public initialContent: ContactFormModuleContent = contactFormContentFactory()
}

export default ContactFormModuleForm
