








import EasyMDE from 'easymde'
import { EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { Component, PropSync, Ref, Vue } from 'vue-property-decorator'

import { Inject, REFRESH_MDE_KEY } from '../../../support'

import { EasyMDESimpleConfig } from './MarkdownEditor.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<MarkdownEditor>({
  name: 'MarkdownEditor',
  mounted () {
    this.boot()
    this.handleOnRefresh()
  },
  destroyed (): void {
    this.instance = null
  }
})
export class MarkdownEditor extends Vue {
  @PropSync('model', { type: String, required: false, default: '' })
  public _model!: string

  @Ref('markdownEditor')
  public readonly markdownEditorRef?: HTMLElement

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  /**
   * Instance of MD editor.
   */
  public instance: EasyMDE | null = null

  /**
   * Initializes MDE with necessary configuration.
   */
  protected boot (): void {
    if (typeof this.markdownEditorRef === 'undefined') {
      throw new Error('There is no element found to instantiate MDE!')
    }

    this.instance = new EasyMDE({
      ...EasyMDESimpleConfig,
      element: this.markdownEditorRef
    })

    this.$emit('initialized', this.instance)

    this.registerEvents()
  }

  /**
   * Listens for `on:blur` event of EasyMDE.
   */
  protected onBlur (instance: EasyMDE): void {
    instance.codemirror.on('blur', () => {
      this.handleOnBlur(instance.value())
    })
  }

  /**
   * Listens for `on:change` event of EasyMDE.
   */
  protected onChange (instance: EasyMDE): void {
    instance.codemirror.on('change', (internalInstance, changeObj) => {
      if (changeObj.origin === 'setValue') {
        return
      }

      const value = instance.value()
      this.handleOnChange(value)
    })
  }

  /**
   * Registers (bind) listeners for EasyMDE instance.
   */
  protected registerEvents (): void {
    if (typeof this.instance === 'undefined' || this.instance === null) {
      return
    }

    this.onBlur(this.instance)
    this.onChange(this.instance)
  }

  /**
   * Handles `on:blur` event of the editor.
   */
  private handleOnBlur (value: string): void {
    this.$emit('blur', value)
  }

  /**
   * Handles `on:change` event of the editor.
   */
  private handleOnChange (value: string): void {
    this._model = value
  }

  /**
   * Handles refresh event of MDE.
   */
  protected handleOnRefresh (): void {
    this.eventBus.handle(REFRESH_MDE_KEY, () => {
      if (typeof this.instance === 'undefined' || this.instance === null) {
        return
      }

      // FIXME!!!
      this.instance.codemirror.setValue(this._model)
      this.instance.codemirror.refresh()
    })
  }
}

export default MarkdownEditor
