


































































































































































import { Component, Watch } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract'

import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { LinkForm } from '../../partials/LinkForm'
import { NavigationForm } from '../../partials/NavigationForm'

import { FooterModule, FooterModuleContentInput, FooterUnresolvedBadge } from '../Footer.contracts'
import { footerContentFactory } from '../Footer.factory'
import { FooterModuleVersion } from '../Footer.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<FooterModuleForm>({
  name: 'FooterModuleForm',
  components: {
    FormFieldset,
    NavigationForm,
    ImageForm,
    LinkForm
  },
  mounted () {
    if (this._content.badge) {
      this.toggleBadge = true
    }
  }
})
export class FooterModuleForm extends AbstractModuleForm<FooterModuleContentInput, FooterModule> {
  protected initialContent = footerContentFactory()
  public toggleBadge: boolean = false
  public badgeConfigured: boolean = false

  public initBadge (badge: FooterUnresolvedBadge | null | undefined) {
    this._content = {
      ...this._content,
      badge: badge
    }
  }

  public get defaultVersion (): boolean {
    return this._version === FooterModuleVersion.Default
  }

  public get simpleVersion (): boolean {
    return this._version === FooterModuleVersion.Simple
  }

  public get image (): UnresolvedImage | null {
    if (!this._content.brandImage) {
      return null
    }
    return {
      image: this._content.brandImage
    }
  }

  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const contentCopy = { ...this._content }
      delete contentCopy.brandImage
      this._content = contentCopy
      return
    }
    this._content = {
      ...this._content,
      brandImage: image.image
    }
  }

  @Watch('toggleBadge')
  public onBadgeShowChange () {
    if (!this.toggleBadge) {
      this._content.badge = undefined
    } else {
      /** configure badge on initial load */
      if (!this.badgeConfigured) {
        this.initBadge(this._content.badge)

        this.badgeConfigured = true
      } else {
        this._content.badge = {
          amount: null,
          desc: null,
          icon: null,
          rate: null,
          sectionTitle: '',
          link: undefined
        }
      }
    }
  }
}

export default FooterModuleForm
